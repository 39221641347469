<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" @submit.prevent="get_etapas(filtro)">
            <v-row>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model.trim="filtro.nombre"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-switch
                  v-model="filtro.inhabilitado"
                  label="Inhabilitada"
                ></v-switch>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-start">
                <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon right small>fas fa-search</v-icon></v-btn>
                <div class="ml-6"><LimpiarFiltro /></div>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="etapas"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:top>
        <div class="d-flex py-2 px-3">
          <v-spacer></v-spacer>
          <v-dialog
            :persistent="edit"
            v-model="dialog"
            max-width="850px"
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-start justify-end mt-n7">
                <v-btn
                  color="success"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="!edit" class="text-h5">Detalle</span>
                <span v-else class="text-h5">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-switch
                  v-if="editedIndex !== -1"
                  v-model="edit"
                  label="Editar"
                ></v-switch>
              </v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.nombre"
                        :rules="[rules.required]"
                        label="Nombre"
                        validate-on-blur
                      ></v-text-field>
                      <v-textarea
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.descripcion"
                        :rules="[rules.required]"
                        label="Descripción"
                        rows="5"
                        auto-grow
                        validate-on-blur
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-textarea
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.wpp"
                        label="Mensaje de WhatsApp"
                        rows="8"
                        auto-grow
                        validate-on-blur
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-switch
                    :readonly="!edit"
                    :inset="!edit"
                    v-model="editedItem.inhabilitado"
                    label="Inhabilitada"
                    hide-details
                  ></v-switch>
                </v-form>
              </v-card-text>

              <v-card-actions class="d-flex justify-end pb-4">
                <v-btn v-if="!edit" color="accent" @click="dialog = false">Volver</v-btn>
                <div v-else>
                  <v-btn
                    color="error"
                    class="mr-4"
                    :disabled="load"
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                  <Confirmar :loading="load" @action="save"/>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="info"
          @click="editItem(item)"
        >
          fas fa-eye
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import Confirmar from '../../components/utils/Confirmar.vue'

export default {
  data() {
    return {
      panel: 0,
      dialog: false,
      edit: true,
      load: false,
      headers: [
        { text: 'Nombre', value: 'nombre'},
        { text: 'Detalle', value: 'actions', align: 'center', sortable: false },
      ],
      etapas: [],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      editedItem: {
        id: '',
        nombre: '',
        descripcion: '',
        wpp: '',
        inhabilitado: false,
      },
      defaultItem: {
        id: '',
        nombre: '',
        descripcion: '',
        wpp: '',
        inhabilitado: false,
      },
    }
  },
  created() {
    this.get_etapas(this.filtro)
  },
  components: {
    LimpiarFiltro, Confirmar
  },
  computed: {
    ...mapState(['filtro']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Etapa' : 'Editar Etapa'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    async get_etapas(filtro) {
      this.load = true
      this.etapas = []

      await db.collection('etapas').where('inhabilitado', '==', filtro.inhabilitado).get()
        .then((res) => {
          res.forEach((doc) => {
            let etapa = doc.data()
            etapa.id = doc.id
            this.etapas.push(etapa)
          })
        })
        .catch((error) => {
          console.log(error)
        })
      
      this.load = false
      this.etapas = this.etapas.filter(etapa => this.removeAccents(etapa.nombre).indexOf(this.removeAccents(filtro.nombre)) >= 0)

    },
    async save () {
      if (this.$refs.form.validate()) {

        this.load = true

        if (this.editedIndex > -1) {
          // edicion
          await db.collection('etapas').doc(this.editedItem.id)
            .update({
              descripcion: this.editedItem.descripcion,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre,
              wpp: this.editedItem.wpp,
            })
            .then(() => {
              Object.assign(this.etapas[this.editedIndex], this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
            
        } else {
          // creacion
          await db.collection('etapas')
            .add({
              descripcion: this.editedItem.descripcion,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre,
              wpp: this.editedItem.wpp,
            })
            .then((docRef) => {
              this.editedItem.id = docRef.id
              this.etapas.push(this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
        }

        this.load = false
        this.dialog = false
      }
    },
    editItem (item) {
      this.edit = false
      this.editedIndex = this.etapas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    async close () {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.edit = true
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.resetValidation()
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  },
}
</script>